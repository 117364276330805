import React from "react";
import government from "../../../static/government/government.png";
import city from "../../../static/government/city.jpg";
import hp from "../../../static/partners/hp.png";
import oracle from "../../../static/partners/oracle.png";
import microsoft from "../../../static/partners/microsoft.png";
import Layout from "../../components/Layout/Layout";

const Government = () => {
  return (
    <Layout>
      <div className="page ">
        <div className="row  page__section --grid-1-1 --align-left">
          <div>
            <h1 className="section-paragraph">INDUSTRY</h1>
            <h1 className="section-title">Government - going Digital</h1>
            <p className="section-description">
              The digital era demands modern government technology. And we are
              there to help transform Government serives.
            </p>
          </div>
          <div style={{ textAlign: "center" }}>
            <img className="section-image" src={government} alt="" />
          </div>
        </div>
        <div className="block--gray">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">
                User-friendly and smart Government services
              </h1>
            </div>
          </div>
          <div className="row page__section --grid-1-1 --align-left">
            <div className="section-description-container ">
              <p className="section-description">
                The Government is rising to the levels required by the digital
                era in order to offer ways for the public to access its services
                digitally.
              </p>
              <p className="section-description">
                Queues and birocratic nightmares should be a thing of the past
                and our Company works together with the authorities on moving
                services on-line for the citizens.
              </p>
              <p className="section-description">
                The success of the Government as a whole relies on a deep
                collaboration among branches, ministries, departments. We work
                together with these organizations on establishing smooth and
                automated flows of data from one authority to the other.
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <img className="section-description-image" src={city} alt="" />
            </div>
          </div>
        </div>
        <div className="block--white">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">The E-Government</h1>
            </div>
          </div>
          <div className="row page__section --grid-2-1 --align-left">
            <div className="section-description-container ">
              <p className="section-description">
                E-Transformation is not only about the tech. It is a new way of
                thinking, a new way of working together - for the people.
              </p>
            </div>
          </div>
          <div className="row page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div className="info-tile  --align-left height-30">
                <div className="icon-container">
                  <i className="tile-icon icon icon-cloud-thunder " />
                </div>
                <p className="section-title-small --align-left ">
                  Digital government transformation
                </p>
                <p className="section-description --align-left ">
                  A smart government needs smart instruments. We are always
                  there to provide the latest technologies for e-transformation.
                </p>
              </div>
            </div>
            <div className="tile-container">
              <div className="info-tile  --align-left height-30">
                <div className="icon-container">
                  <i className="tile-icon icon icon-lock " />
                </div>
                <p className="section-title-small --align-left ">
                  Security and privacy
                </p>
                <p className="section-description --align-left ">
                  Processing personal data requires the highest standards in
                  matters of security and privacy. Our products and the hardware
                  we provide through our venders meet those expectations.
                </p>
              </div>
            </div>
            <div className="tile-container">
              <div className="info-tile  --align-left height-30">
                <div className="icon-container">
                  <i className="tile-icon icon icon-sun-inv " />
                </div>
                <p className="section-title-small --align-left ">
                  Customer Experience
                </p>
                <p className="section-description --align-left ">
                  The customer experience is a decisive factor in whether a
                  product will be used by the general public. Our UI/UX efforts
                  rise up to the challenge.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="block--gray">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">
                The nexus of tech and smart government
              </h1>
            </div>
          </div>

          <div className="row  page__section --grid-2-1 --align-left">
            <div className="tile-container">
              <div
                className="accent-tile --align-left height-25"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="accent-tile__content">
                  <p className="section-title-small --align-left ">
                    Supporting and modernizing education
                  </p>
                  <p className="section-description --align-left ">
                    Education is the foundation of an advanced society and it
                    needs constantl re-alignment with the requirements of the
                    ever more complex economic and political landscape. We
                    participate in this process with the best hardware we can
                    supply through our vendors and the best e-learning platforms
                    to drive education forward.
                  </p>
                </div>
              </div>
            </div>

            <div className="tile-container">
              <div
                className="accent-tile --align-left height-25"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="accent-tile__content">
                  <p className="section-title-small --align-left ">
                    Advanced healthcare
                  </p>
                  <p className="section-description --align-left ">
                    Great health is imperative for a well-functioning society.
                    And keeping costs down is critical. We consult and supply
                    technologies to do just that.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row  page__section --grid-1-2 --align-left">
            <div className="tile-container">
              <div
                className="accent-tile --align-left height-25"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="accent-tile__content">
                  <p className="section-title-small --align-left ">
                    Social sphere
                  </p>
                  <p className="section-description --align-left ">
                    Quality social services are a hallmark of an advanced
                    society and we support the efforts of the government to help
                    the vulnerable through country-wide software platforms that
                    deliver to those in need.
                  </p>
                </div>
              </div>
            </div>

            <div className="tile-container">
              <div
                className="accent-tile --align-left height-25"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="accent-tile__content">
                  <p className="section-title-small --align-left ">
                    Intelligence in fighting crime
                  </p>
                  <p className="section-description --align-left ">
                    Together with our partners we supply state of the art
                    technology for threat detection, risk assessment,
                    operational planning and execution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block--white">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">Our Partners</h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div className="partners-tile --align-left height-40">
                <div className="partners-image-container">
                  <img
                    style={{ height: "7rem" }}
                    className="partners-image"
                    src={hp}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Through our partner HPE, we supply, configure and maintain
                    top of the line servers, storage and networking equipment
                    for our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="partners-tile --align-left height-40">
                <div className="partners-image-container">
                  <img
                    style={{ height: "2rem" }}
                    className="partners-image"
                    src={oracle}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Oracle databases are present in the wide majority of
                    government organizations and our specialists are always
                    ready to offer professional assistance in operating the
                    critical stores of data.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="partners-tile --align-left height-40">
                <div className="partners-image-container">
                  <img
                    style={{ height: "4rem" }}
                    className="partners-image"
                    src={microsoft}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    The Microsoft software is fundamental for the daily
                    operations of most organizations. We are here, on the
                    ground, ready to supply, maintain the wide range of
                    Microsoft applications.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Government;
